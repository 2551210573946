import React, { useEffect, useState } from 'react';

// ** MUI Imports
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { useTheme } from '@mui/material/styles';
import CardHeader from '@mui/material/CardHeader';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import ReactApexcharts from 'react-apexcharts';

// ** Icons Imports
import DotsVertical from 'mdi-material-ui/DotsVertical';

// ** Service
import statsService from 'src/services/statsService';

const Sites = () => {
    // ** Hook
    const theme = useTheme();
    console.log('Theme', theme)
    const [data, setData] = useState({});
    const [options, setOptions] = useState({
        "chart": {
            "stacked": true,
            "parentHeightOffset": 0,
            "toolbar": {
                "show": false
            }
        },
        "plotOptions": {
            "bar": {
                "borderRadius": 50,
                "columnWidth": "15%",
                "endingShape": "rounded",
                "startingShape": "rounded"
            }
        },
        "colors": [
            theme.palette.success.main,
            theme.palette.error.main,
            theme.palette.secondary.main,
            theme.palette.primary.main,
            theme.palette.info.light,
        ],
        "grid": {
            "strokeDashArray": 7,
            "borderColor": "rgba(58, 53, 65, 0.12)",
            "padding": {
                "left": 0,
                "bottom": -10
            }
        },
        "legend": {
            "show": false
        },
        "dataLabels": {
            "enabled": false
        },
        "stroke": {
            "width": 6,
            "curve": "smooth",
            "lineCap": "round",
            "colors": [
                "#FFF"
            ]
        },
        "states": {
            "hover": {
                "filter": {
                    "type": "none"
                }
            },
            "active": {
                "filter": {
                    "type": "none"
                }
            }
        },
        "xaxis": {
            "axisTicks": {
                "show": false
            },
            "axisBorder": {
                "show": false
            },
            "categories": [
                "S1",
                "Others"
            ],
            "labels": {
                "style": {
                    "colors": "rgba(58, 53, 65, 0.38)"
                }
            },
            "convertedCatToNumeric": false
        },
        "yaxis": {
            "labels": {
                "offsetY": 2,
                "offsetX": -10,
                "style": {
                    "colors": "rgba(58, 53, 65, 0.38)"
                }
            }
        },
        "responsive": [
            {
                "breakpoint": 1536,
                "options": {
                    "plotOptions": {
                        "bar": {
                            "columnWidth": "45%"
                        }
                    }
                }
            },
            {
                "breakpoint": 1200,
                "options": {
                    "plotOptions": {
                        "bar": {
                            "columnWidth": "50%"
                        }
                    }
                }
            },
            {
                "breakpoint": 600,
                "options": {
                    "plotOptions": {
                        "bar": {
                            "columnWidth": "45%"
                        }
                    }
                }
            },
            {
                "breakpoint": 430,
                "options": {
                    "plotOptions": {
                        "bar": {
                            "columnWidth": "55%"
                        }
                    }
                }
            }
        ]
    });

    const path = 'workrequests/summary';

    useEffect(() => {
        statsService.all(path, {export_format: 'apex'})
            .then((res) => {
                setData(res?.data);
                setOptions({...options});
            });
    }, []);

    console.log('Data', data);

    return (
        <Card>
            <CardHeader
                title="Work Requests Overview"
                titleTypographyProps={{
                    sx: { lineHeight: '2rem !important', letterSpacing: '0.15px !important' }
                }}
                action={
                    <IconButton
                        size="small"
                        aria-label="settings"
                        className="card-more-options"
                        sx={{ color: 'text.secondary' }}>
                        <DotsVertical />
                    </IconButton>
                }
            />
            <CardContent>
                <ReactApexcharts
                    type="bar"
                    height={308}
                    options={options}
                    series={data?.series || []}
                />
            </CardContent>
        </Card>
    );
};

export default Sites;
